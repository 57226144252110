<div style="width: 100vw; height: 100vh; overflow-y: scroll">
  <header class="header">
    <nav
      class="navbar navbar-expand-lg navbar-light bg-light"
      style="padding-top: 0.5rem; padding-bottom: 0.5rem"
    >
      <div class="container">
        <a class="navbar-brand" href="#">
          <img src="assets/img/logo.png" />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mb-2 ms-5 mb-lg-0">
            <li class="nav-item">
              <a [routerLink]="['/']" class="nav-link">Home</a>
            </li>

            <li class="nav-item">
              <a class="nav-link" [routerLink]="['network']">Network</a>
            </li>
            <li class="nav-item">
              <a href="https://umyomilitary.site" class="nav-link"
                >Military Connects</a
              >
            </li>
            <li class="nav-item">
              <a href="https://umyobizdfw.com/" class="nav-link"
                >DFW Connects</a
              >
            </li>
            <li class="nav-item">
              <a href="https://umyotruckers.site/" class="nav-link"
                >Truckers Connects</a
              >
            </li>
            <li class="nav-item">
              <a href="https://umyohbcu.site" class="nav-link"
                >HBCU’s Connects</a
              >
            </li>

            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Next Pages
              </a>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item" href="https://umyodivine9.site"
                    >Divine Nine Connects</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="https://nubianhigherlearning.com"
                    >Nubians Connects</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="https://umyoentertainment.site"
                    >Entertainers Connects</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="https://umyostylist.com"
                    >Hair and Beauty Connects</a
                  >
                </li>
              
              
               
                <li>
                  <a class="dropdown-item" href="https://umyobizatl.com"
                    >ATL Connects</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="https://umyocolleges.site"
                    >College Connects</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="../school.html"
                    >Jr and High School Connects</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="../greek.html"
                    >Greeks Connects</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="https://umyolawyer.site"
                    >Lawyer Connects</a
                  >
                </li>
               
                <li>
                  <a class="dropdown-item" href="https://umyorealtor.site"
                    >Realtors Connects</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="../automotive.html"
                    >Automotive Connects</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="https://prolivingbiz.com"
                    >Pro-Living Connects</a
                  >
                </li>

                <li>
                  <a
                    class="dropdown-item"
                    href="https://lieutenantsacademydfw.org"
                    >LTA Connects</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="https://Umyopd.site"
                    >Police Connects</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="https://Umyomedical.site"
                    >Medical Connects</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="https://Umyolgbtq.site"
                    >LGBTQ Connects</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="https://Umyoauthor.site"
                    >Authors Connects</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="https://umyonfl.site"
                    >NFL Connects</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="https://umyonba.site"
                    >NBA Connects</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="https://prestigiousideas.com/"
                    >Over 50 Connects</a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="https://umyosportscards.com/"
                    >Umyosports Connects </a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="https://umyoespanol.site/"
                    >Hispanics Connects </a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="https://umyorestaurants.com/"
                    >Restaurants Connects </a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="https://umyonightlife.com/"
                    >Nightlife Connects </a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="https://umyoreligions.site/"
                    >Religions Connects </a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="https://umyounions.site/"
                    >Unions Connects </a
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="https://umyocard.com"
                    >AllBiz Types Connects </a
                  >
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
  <section id="middel-header" class="lft-hero">
    <div class="container-fluid">
      <div class="middel-header-content">
        <div class="lft-video">
          <video
            autoplay
            loop
            [src]="videoUrl"
            style="width: 100%; height: 100%"
            [muted]="true"
          ></video>
          <div class="overlay"></div>
        </div>
        <div class="container">
          <div class="row g-5 align-items-center">
            <div class="col-lg-12">
              <div class="page-width">
                <div class="lft-hero__content">
                  <h1 class="mb-3">
                    Where Innovation Meets Community Empowerment. The Ultimate
                    Business, Community & Networking Directories
                  </h1>
                </div>
              </div>
              <div class="search">
                <div class="input-group">
                  <input
                    type="text"
                    placeholder="Search Here"
                    aria-describedby="button-addon1"
                    class="form-control"
                  />
                  <div class="input-group-append">
                    <button
                      id="button-addon1"
                      type="submit"
                      class="btn btn-link text-primary"
                    >
                      <span class="material-symbols-sharp">search</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="text-section">
    <div class="container mt-5">
      <h4>Welcome to The UMYO Network</h4>
      <h1>
        Where Innovation Meets Community<br />
        Empowerment
      </h1>
      <p>
        Dive into a world of endless possibilities as we bring together the best
        of popular platforms under one roof. Experience the thrill of sites
        similar to TikTok, the creativity of YouTube, the seamless connectivity
        of Zoom, the chatter of Twitter, the excitement of Tinder Date, Only
        Fans, Constant Contact, Go Daddy, Games, PPV, Live Streaming, Podcasts,
        UMYO Mail and the warmth of hosting websites—all in a single app!
      </p>
      <a class="moreless-button3 btn btn-dark" href="#Umyo" style="color: white"
        >Read More</a
      >
      <div class="moretext3 mt-4" style="display: none">
        <p>
          But we're not just a fusion of platforms; we're a movement. The UMYO
          Network goes beyond entertainment; we're on a mission to rebuild,
          reconnect, and revitalize communities. Say goodbye to the ordinary,
          and join us in creating a space where every voice is heard, and every
          member is valued.
        </p>
        <p>
          Safety is our priority! We're not just a social hub; we're a guardian
          against violence. The UMYO Network is committed to reporting and
          preventing crimes, making your virtual world a safer place. Together,
          we're building a community where everyone can thrive.
        </p>
        <p>
          And the excitement doesn't stop there! We love giving back to our
          community, and that's why we have daily giveaways exclusively for our
          members based upon registration. Your membership isn't just a pass;
          it's a ticket to a world of surprises and rewards.
        </p>
        <p>
          Join us on The UMYO Network and be part of a revolution—a revolution
          that blends the best of technology with the heart of community. Your
          journey to a new era of connectivity begins here!
        </p>
      </div>
    </div>
  </section>
  <section id="text-section2">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12 mt-2">
          <h4>PLAN OF ACTION : Report Violent Crimes</h4>
          <h1>Promoting Community Business Collaboration</h1>
          <p>
            Community-business collaboration plays a crucial role in addressing
            violent crimes by fostering a holistic and multifaceted approach to
            crime prevention and community well-being. Here are key points
            highlighting its importance:
          </p>
          <h4>1. Shared Responsibility:</h4>
          <p>
            Collaboration between the community and businesses recognizes that
            preventing violent crimes is a shared responsibility. By working
            together, both entities can contribute their unique resources,
            expertise, and perspectives to address the root causes of violence.
          </p>
          <a
            class="moreless-button6 btn btn-dark"
            href="#crimes"
            style="color: white"
            >Read More</a
          >
        </div>
        <div class="col-lg-6 col-md-12">
          <img src="assets/img/home.png" class="img-fluid" />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="moretext6 mt-4" style="display: none">
            <h4>2. Resource Mobilization:</h4>
            <p>
              Businesses often have financial resources, technology, and
              networks that can supplement community efforts to combat crime.
              This collaboration allows for the mobilization of resources that
              might not be readily available within the community alone.
            </p>
            <h4>3. Economic Development:</h4>
            <p>
              A safer community is conducive to economic development. Businesses
              thrive in environments where crime is low, leading to increased
              investment, job opportunities, and overall community prosperity.
              This positive cycle reinforces the importance of collaboration in
              creating a safer and more economically stable community.
            </p>
            <h4>4. Community Engagement:</h4>
            <p>
              Businesses can engage with the community through various
              initiatives, such as sponsoring youth programs, supporting
              education, and creating job opportunities. These efforts
              contribute to community empowerment and help address the
              underlying factors that may contribute to violent behavior.
            </p>
            <h4>5. Information Sharing:</h4>
            <p>
              Businesses often have access to valuable information and
              surveillance systems that can aid law enforcement and community
              organizations in preventing and solving crimes. Sharing this
              information can enhance the overall effectiveness of crime
              prevention strategies.
            </p>
            <h4>6. Social Capital Building:</h4>
            <p>
              Collaboration fosters the development of social capital, which
              refers to the connections, trust, and shared values within a
              community. Building social capital is essential for creating a
              strong, united front against violence and criminal activities.
            </p>
            <h4>7. Innovative Solutions:</h4>
            <p>
              Community and Business owners bring innovation and expertise to
              the table, enabling the development of creative and effective
              solutions to address the complex challenges associated with
              violent crimes. Collaborative efforts allow for the implementation
              of diverse strategies that can adapt to changing circumstances.
            </p>
            <h4>8. Improved Policing Strategies:</h4>
            <p>
              Collaboration enhances communication between businesses, the
              community, and law enforcement agencies. This improved
              communication can lead to more effective policing strategies,
              better coordination, and a quicker response to emerging threats.
            </p>
            <p>
              In summary, community-business collaboration is essential for
              creating safer, more resilient communities. By combining the
              strengths of both entities, a comprehensive and sustainable
              approach to addressing violent crimes can be developed, tackling
              the root causes and promoting long-term community well-being.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="umyo-content">
    <div class="container-fluid content p-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <img src="assets/img/content.png" class="img-fluid" />
          </div>
          <div class="col-lg-6 col-md-12 mt-4">
            <h2>UMYO Contests</h2>
            <h1>Promoting Community Business Collaboration</h1>
            <p>
              By encouraging more subscriptions to our platforms, we are able to
              offer increased rewards and money giveaways to our valued
              community members. As our platform grows, so do the benefits for
              our dedicated users. Join us in this exciting journey and stand a
              chance to win bigger prizes and be part of a thriving community.
            </p>
            <a
              class="moreless-button4 btn btn-dark"
              href="#contents"
              style="color: white"
              >Read More</a
            >
            <div class="moretext4 mt-4" style="display: none">
              <ul>
                <li>
                  Monthly Tier Packages Based Upon Monthly Budget
                  (Free-$5.99-$19.99)
                </li>
                <li>Community Brands Directory For You Via Social Media</li>
                <li>Daily $$$ Giveaways to registered members</li>
                <li>Business Seeking Exposure - $79 & up monthly</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="logo">
    <div class="container-fluid">
      <h4>UMYO CONNECTS</h4>
      <h1 class="mb-4">
        The Ultimate Business, Community<br />
        & Networking Directories
      </h1>
    </div>
    <div class="container">
      <!--  <img src="assets/img/logo1.png" class="img-fluid">
       <img src="assets/img/logo2.png" class="img-fluid">
       <img src="assets/img/logo3.png" class="img-fluid"> -->
      <div class="row">
        <div class="col-md-2 col-sm-3">
          <img src="assets/logo/1.png" class="img-fluid" />
        </div>
        <div class="col-md-2 col-sm-3">
          <img src="assets/logo/2.png" class="img-fluid" />
        </div>
        <div class="col-md-2 col-sm-3">
          <img src="assets/logo/3.png" class="img-fluid" />
        </div>
        <div class="col-md-2 col-sm-3">
          <img src="assets/logo/4.png" class="img-fluid" />
        </div>
        <div class="col-md-2 col-sm-3">
          <img src="assets/logo/5.png" class="img-fluid" />
        </div>
        <div class="col-md-2 col-sm-3">
          <img src="assets/logo/6.png" class="img-fluid" />
        </div>
        <div class="col-md-2 col-sm-3">
          <img src="assets/logo/7.png" class="img-fluid" />
        </div>
        <div class="col-md-2 col-sm-3">
          <img src="assets/logo/8.png" class="img-fluid" />
        </div>
        <div class="col-md-2 col-sm-3">
          <img src="assets/logo/9.png" class="img-fluid" />
        </div>
        <div class="col-md-2 col-sm-3">
          <img src="assets/logo/10.png" class="img-fluid" />
        </div>
        <div class="col-md-2 col-sm-3">
          <img src="assets/logo/11.png" class="img-fluid" />
        </div>
        <div class="col-md-2 col-sm-3">
          <img src="assets/logo/12.png" class="img-fluid" />
        </div>
        <div class="col-md-2 col-sm-3 pt-lg-5">
          <img src="assets/logo/13.png" class="img-fluid" />
        </div>
        <div class="col-md-2 col-sm-3">
          <img src="assets/logo/14.png" class="img-fluid" />
        </div>
        <div class="col-md-2 col-sm-3">
          <img src="assets/logo/15.png" class="img-fluid" />
        </div>
        <div class="col-md-2 col-sm-3 pt-lg-3">
          <img src="assets/logo/16.png" class="img-fluid" />
        </div>
        <div class="col-md-2 col-sm-3">
          <img src="assets/logo/17.png" class="img-fluid" />
        </div>
        <div class="col-md-2 col-sm-3">
          <img src="assets/logo/18.png" class="img-fluid" />
        </div>
      </div>
    </div>
  </section>
  <section id="card">
    <div class="container">
      <h1>
        What Makes UMYO Connects Different<br />
        from Other Card Base Websites
      </h1>
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="column">
            <div class="card">
              <h4>Digital Video Business Cards</h4>
              <ul>
                <li>
                  Add Video infomercial, Testimonies, and Reviews and more.
                </li>
                <li>
                  Various Directories that Caters to Specific Type Businesses
                </li>
                <li>Based upon your budget</li>
                <li>Free Membership</li>
                <li>Paid membership</li>
                <li>Tier packages</li>
                <li>
                  Social Media & Website link, About me, Contact & Google map
                  added to Cards
                </li>
                <li>
                  Google analytics - see how many times your card is shared and
                  viewed
                </li>
                <li>Various Card Templates to choose from</li>
                <li>Marketing Card for Each Employee</li>
                <li>Unlimited Images</li>
                <li>Unlimited Videos</li>
                <li>Logo</li>
                <li>Community based Ucards</li>
                <li>Owned and Operated by Community</li>
                <li>Daily Communiaty Giveaways based upon memberships</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="column">
            <div class="card">
              <h4>Built in Networking Platform</h4>
              <ul>
                <li>
                  Front End of Platform displays Digital Cards. Backend of
                  platform is for Social Networking
                </li>
                <li>Meet & Greet Members</li>
                <li>Search & Befriend Members</li>
                <li>Chat with Business Owners & UMYO Community Members</li>
                <li>Network with other members or Business owners</li>
                <li>Private Messaging</li>
                <li>Save Contacts</li>
                <li>Create Groups</li>
                <li>QR Code</li>
                <li>Save Card</li>
                <li>Share Card features to Popular Social Media Sites</li>
                <li>Share Card features to Popular Social Media Sites</li>
                <li>Community Brands Platform</li>
                <li>Logo</li>
                <li>Podcast/Conference</li>
                <li>Privacy</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="column">
            <div class="card">
              <h4>Built in Search Engine</h4>
              <p>
                Search By Name, Business Type,School, Location, City, State,
                Gender, Race & more
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="directories">
    <div class="container-fluid">
      <h1>Popular Directories</h1>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12 p-0 m-0">
          <img src="assets/img/logo-menu.jpg" class="img-fluid logo1" />
        </div>
        <!-- 
         <div class="col-md-3 p-0 m-0">
           <img src="assets/img/student.png" class="img-fluid logo1">
         </div> -->
      </div>
    </div>
  </section>

  <section id="umyo-content">
    <div class="container-fluid content p-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-12 mt-4 p-lg-5">
            <h1>Shared Platforms For All Other Directories</h1>
            <a
              class="moreless-button5 btn btn-dark"
              href="#Umyo"
              style="color: white !important"
              >Read More</a
            >
          </div>
          <div class="col-lg-6 col-md-12">
            <img src="assets/img/www.png" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="moretext5" style="display: none">
    <section id="short-video">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <img src="assets/img/video.png" class="img-fluid" />
            <h1>Short Videos</h1>
            <p>
              Creating short videos entails producing and sharing concise video
              content that is typically brief in duration, often lasting
              anywhere from a few seconds to a few minutes.
            </p>
            <a class="moreless-button" href="#first">Read More</a>
            <div class="moretext" style="display: none">
              <p class="mt-3">
                These short videos are designed to capture attention quickly and
                are well-suited for sharing on platforms like TikTok, Instagram
                Reels, or Snapchat. Users can use various creative tools and
                features to record, edit, and enhance their videos to tell
                stories, share moments, provide entertainment, or convey
                messages in a concise and engaging format. The goal is to engage
                the audience and make an impact in a short span of time.
              </p>
            </div>
          </div>
          <div class="col-12 mt-5">
            <img src="assets/img/tweets.png" class="img-fluid" />
            <h1>UMYO Tweets</h1>
            <p>
              Similar to Twitter, our community based UTweets is a microblogging
              platform where users share short messages called tweets, limited
              to 280 characters. It's a hub for users to add real-time updates,
              discussions, and a variety of content, often accompanied by
              hashtags to categorize topics. We have added additional features
            </p>
            <a class="moreless-button2" href="#seconds">Read More</a>
            <div class="moretext2" style="display: none">
              <p class="mt-3">
                These short videos are designed to capture attention quickly and
                are well-suited for sharing on platforms like TikTok, Instagram
                Reels, or Snapchat. Users can use various creative tools and
                features to record, edit, and enhance their videos to tell
                stories, share moments, provide entertainment, or convey
                messages in a concise and engaging format. The goal is to engage
                the audience and make an impact in a short span of time.
              </p>
            </div>
          </div>
          <div class="col-12 mt-5">
            <img src="assets/img/uchat.png" class="img-fluid" />
            <h1>UChatYes</h1>
            <p>
              uChatYes.com - Similar to ChatGPT,, uChatYes creates unique and
              engaging content that will increase conversions and drive
              sales.Harness the power of artificial intelligence and transform
              the way you produce unique content.
            </p>
            <h4>Transcriptions</h4>
            <p>
              Extract the contents of audio files and turn them into fully
              editable documents. Generate original content for your website,
              marketing, and social activities.
            </p>
          </div>
          <div class="col-12 mt-5">
            <img src="assets/img/music.png" class="img-fluid" />
            <h1>UMYO Music</h1>
            <p>
              Similar to Spotify, Umyomusic is a popular music streaming service
              that allows users to listen to a vast catalog of songs, albums,
              and playlists from a wide range of artists and genres. It was
              founded in [Insert founding location and year] and has since
              become one of the most widely used music streaming platforms in
              the world.
            </p>
          </div>
          <div class="col-12 mt-5">
            <img src="assets/img/market.png" class="img-fluid" />
            <h1>UMYO Market</h1>
            <p>
              Similar to amazon, UMYO Market serves as a dedicated platform for
              pop-up shops and entrepreneurs seeking to showcase their products
              on a digital shelf. It offers a unique marketplace for these
              sellers to reach a global customer base and bring their innovative
              products to a wider audience.
            </p>
          </div>
          <div class="col-12 mt-5">
            <img src="assets/img/hosting.png" class="img-fluid" />
            <h1>UMYO Host</h1>
            <p>
              Similar to godaddy, UMYO Host is a hosting website, often referred
              to as a web hosting service provider, is a company that offers the
              necessary infrastructure and technology for individuals,
              businesses, or organizations to store and make their websites
              accessible on the internet. These services typically include
              server space, domain name registration, website file storage,
              email hosting, and technical support. Hosting websites allow users
              to publish their websites, making them available for online
              visitors to access 24/7.
            </p>
          </div>
          <div class="col-12 mt-5">
            <img src="assets/img/cp.png" class="img-fluid" />
            <h1>CP Swipe</h1>
            <p>
              Similar to Tinder date and Only fans, "CP Swipe" is a combination
              of both. A combination social encounter dating app and Only Fans,
              where individuals meet in person with the intention of getting to
              know each other romantically or platonically. As well as an online
              platform where content creators share exclusive, often
              adult-oriented, content with subscribers for a fee. Both on one
              platform are related to social interactions and connections,
              albeit in different ways—one facilitates real-life meetings, while
              the other involves online content creation and subscription-based
              access.
            </p>
          </div>
          <div class="col-12 mt-5">
            <img
              src="assets/img/tok.png"
              class="img-fluid"
              style="width: 4rem; height: 4rem"
            />
            <h1>UMYO Talk</h1>
            <p>
              Similar to whatsapp, UMYO Talk is a popular messaging app that
              allows users to send text messages, make voice and video calls,
              and share multimedia content with individuals or groups. It
              operates over the internet and is available on various platforms,
              making it a convenient and widely used communication tool.
            </p>
          </div>
          <div class="col-12 mt-5">
            <img src="assets/img/event.png" class="img-fluid" />
            <h1>UMYO Events</h1>
            <p>
              SImilar to event brite, UMYO is a combination of planning events
              with groups, live streaming and more. A Platform primarily used
              for event management and ticketing. It allows event organizers to
              create, promote, and sell tickets for a wide range of events,
              including conferences, concerts, festivals, fundraisers, and more.
              UMYO Event provides tools for event registration, ticket sales,
              marketing, and attendee management, making it easier for both
              event organizers and attendees to plan and participate in events.
              It is commonly used by event planners, organizers, and businesses
              to streamline the process of hosting and managing events.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section id="sky-text">
      <div class="container-fluid content">
        <div class="container">
          <div class="row">
            <div class="col-12 mb-2 mt-4">
              <img src="assets/img/edu.png" class="img-fluid" />
              <h1>UMYO Educator</h1>
              <p>
                Similar to LMS, Umyo Educator, a software application or
                platform designed to facilitate the administration,
                documentation, tracking, reporting, and delivery of educational
                courses or training programs. Here are some key aspects of a
                learning management system:
              </p>
              <h4>1. Course Management:</h4>
              <p>
                LMS platforms allow for the creation, organization, and
                management of courses. This includes features like uploading
                course materials, creating assignments, and structuring content
                in a way that facilitates learning.
              </p>
              <h4>2. User Management:</h4>
              <p>
                LMS systems typically include user management features, allowing
                administrators to add, remove, or modify user accounts. This is
                crucial for tracking individual progress and managing access to
                different courses or modules.
              </p>
              <h4>3. Assessment and Evaluation:</h4>
              <p>
                LMS platforms often provide tools for creating quizzes, exams,
                and other assessment methods. These features help in evaluating
                the learners' understanding of the material and tracking their
                progress.
              </p>
              <h4>4. Collaboration and Communication:</h4>
              <p>
                Many LMS platforms include communication tools such as
                discussion forums, chat, and messaging systems. These features
                facilitate interaction between learners, instructors, and
                sometimes even peers.
              </p>
              <h4>5. Tracking and Reporting:</h4>
              <p>
                LMS systems offer tracking and reporting functionalities,
                allowing administrators and instructors to monitor the progress
                of learners. This includes data on completion rates, assessment
                scores, and other relevant metrics.
              </p>
              <h4>6. Content Delivery:</h4>
              <p>
                LMS platforms support various formats of content delivery,
                including multimedia, documents, and interactive elements. This
                flexibility enables diverse and engaging learning experiences.
              </p>
              <h4>7. Integration with Other Systems:</h4>
              <p>
                Many LMS platforms are designed to integrate with other systems,
                such as HR systems, to streamline processes like user onboarding
                and data management.
              </p>
              <h4>8. Mobile Accessibility:</h4>
              <p>
                With the increasing use of mobile devices, many LMS platforms
                offer mobile-friendly interfaces or dedicated apps to allow
                learners to access content on the go.
              </p>
              <h4>9. Customization and Branding:</h4>
              <p>
                Organizations often appreciate the ability to customize the look
                and feel of their LMS to align with their branding and create a
                more personalized learning experience.
              </p>
              <h4>10. Security and Compliance:</h4>
              <p>
                Given the sensitive nature of educational and training data, LMS
                platforms prioritize security features. They often include
                authentication protocols, data encryption, and compliance with
                relevant regulations.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="tv">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <img src="assets/img/tv.png" class="img-fluid" />
            <h1>VibetalkTV</h1>
            <p>
              VibetalkTV transforms your online interactions into a dynamic
              social experience. VibeTalk TV Connect is your comprehensive
              platform for connecting, sharing, and engaging with friends,
              family, and the global community. Loaded with a wide range of
              robust functionalities, UMYO ConnectVibetalktv offers all the
              tools you require in a social networking application.
            </p>
          </div>
          <div class="col-12 mt-5">
            <img src="assets/img/tube.png" class="img-fluid" />
            <h1>UMYO Tube</h1>
            <p>
              Similar to You-Tube, UMYOTube is a platform for content creators
              and users looking to share articles, videos, conduct live
              streaming with external cameras, offer pay-per-view events, enable
              donations for online channels, and more.
            </p>
          </div>
          <div class="col-12 mt-5">
            <img src="assets/img/sms.png" class="img-fluid" />
            <h1>UMYO SMS</h1>
            <p>
              Similar to Constant Contact, UMYOSMS is an email marketing
              platform that enables businesses and organizations to create,
              send, and track email campaigns. It provides tools for building
              and managing email lists, designing professional-looking emails,
              and analyzing the performance of email marketing efforts, making
              it a valuable resource for email marketing and communication
              strategies.
            </p>
          </div>
          <div class="col-12 mt-5">
            <img src="assets/img/lms.png" class="img-fluid" />
            <h1>UMYO LMS</h1>
            <p>
              UMYO LMS, or Learning Management System, is a software platform
              designed for the management, delivery, and tracking of educational
              and training content. It provides a centralized digital
              environment for creating, organizing, and delivering courses,
              training materials, and assessments. UMYO LMS platforms are
              commonly used in educational institutions, corporations, and
              organizations to facilitate e-learning and training programs,
              making it easier to manage, monitor, and optimize the learning
              experience for students or employees.
            </p>
            <h3>UMYO'S ADVANCED LMS & SOCIAL MEDIA NETWORKING</h3>
            <a class="moreless-button7" href="#sev">Read More</a>
            <div class="moretext7" style="display: none">
              <p>
                Similar to UMYO Educator, only, it's our LMS on steroids. Here
                you can network, podcast, conference, live stream, audio stream,
                PPV, donation, and more. IT’S KRAZY!!! It’s a community
                networking platform that can be used for educational purposes as
                well as edutainment purposes. Rather you prefer zoom or you
                prefer our version of zoom, its community based and educational
                as well.
              </p>
              <small
                >Here are some key aspects of a learning management
                system:</small
              >
              <h4>1. Course Management:</h4>
              <p>
                LMS platforms allow for the creation, organization, and
                management of courses. This includes features like uploading
                course materials, creating assignments, and structuring content
                in a way that facilitates learning.
              </p>
              <h4>2. User Management:</h4>
              <p>
                <strong> LMS systems typically include user management</strong>
                features, allowing administrators to add, remove, or modify user
                accounts. This is crucial for tracking individual progress and
                managing access to different courses or modules.
              </p>
              <h4>3. Assessment and Evaluation:</h4>
              <p>
                LMS platforms often provide tools for creating quizzes, exams,
                and other assessment methods. These features help in evaluating
                the learners' understanding of the material and tracking their
                progress.
              </p>
              <h4>4. Collaboration and Communication:</h4>
              <p>
                Many LMS platforms include communication tools such as
                discussion forums, chat, and messaging systems. These features
                facilitate interaction between learners, instructors, and
                sometimes even peers.
              </p>
              <h4>5. Tracking and Reporting:</h4>
              <p>
                LMS systems offer tracking and reporting functionalities,
                allowing administrators and instructors to monitor the progress
                of learners. This includes data on completion rates, assessment
                scores, and other relevant metrics.
              </p>
              <h4>6. Content Delivery:</h4>
              <p>
                LMS platforms support various formats of content delivery,
                including multimedia, documents, and interactive elements. This
                flexibility enables diverse and engaging learning experiences.
              </p>
              <h4>7. Integration with Other Systems:</h4>
              <p>
                Many LMS platforms are designed to integrate with other systems,
                such as HR systems, to streamline processes like user onboarding
                and data management.
              </p>
              <h4>8. Mobile Accessibility:</h4>
              <p>
                With the increasing use of mobile devices, many LMS platforms
                offer mobile-friendly interfaces or dedicated apps to allow
                learners to access content on the go.
              </p>
              <h4>9. Customization and Branding:</h4>
              <p>
                Organizations often appreciate the ability to customize the look
                and feel of their LMS to align with their branding and create a
                more personalized learning experience.
              </p>
              <h4>10. Security and Compliance:</h4>
              <p>
                Given the sensitive nature of educational and training data, LMS
                platforms prioritize security features. They often include
                authentication protocols, data encryption, and compliance with
                relevant regulations.
              </p>
            </div>
          </div>
          <div class="col-12 mt-5">
            <img src="assets/img/vpn.png" class="img-fluid" />
            <h1>UMYO VPN</h1>
            <p>
              Similar to Norton VPN, UMYO VPN Protects you and your guest
              browsers privacy. Browse with a virtual I.P. Address that helps
              you hide your location and lets you view the web with enhanced
              online privacy within the android and desktop.
            </p>
          </div>
          <div class="col-12 mt-4">
            <img
              src="assets/img/web.png"
              class="img-fluid"
              style="width: auto; height: 6rem; margin-left: -38px"
            />
            <h1>UMYO Web Design</h1>
            <p>
              UMYO Web design is the process of planning, creating, and
              arranging the visual and structural elements of a website. It
              involves the use of various design principles, such as layout,
              color, typography, and user interface, to ensure that a website is
              both aesthetically appealing and user-friendly. Web designers work
              to create a well-organized, visually engaging, and functional
              digital environment that effectively conveys information, promotes
              businesses, and enhances user experiences on the internet.
            </p>
          </div>
          <div class="col-12 mt-5">
            <img src="assets/img/confernece.png" class="img-fluid" />
            <h1>UMYO Conferencing</h1>
            <p>Similar to Zoom, UMYO Conferencing lets you</p>
            <ul>
              <li>
                Share External Video, Share Screen, Create Rooms, Create
                Backrooms.
              </li>
              <li>
                Shared Notes, Public Chat/Save Copy/Delete, Manage Presentation.
              </li>
              <li>
                Start A Poll, Select Random User, Mute, Leave Audio, Share
                Webcam.
              </li>
              <li>
                Manage Presentation, Raise Hands, Recording, Keyboard Shortcuts
                & More.
              </li>
              <li>
                Weekly Support Channels for Students, Student Only Channels.
              </li>
              <li>
                Audio Conference -Enable you to conference with only audio with
                optional screen share features like a group voice call.
              </li>
              <li>
                Video Conference -enables you to conference with others with
                audio and video with optional screen sharing features.
              </li>
              <li>
                Webinar -Enables you to stream yourself live with video and
                audio with optional screen sharing feature.
              </li>
              <li>
                Live Classes ( backrooms) -Enables you to stream live with audio
                and video , where you can see and hear anyone but they can see
                and hear only you , not each other.
              </li>
              <li>
                Podcast -Enables you to stream your audio live with others who
                are invited.They can only hear your voice over audio stream.
              </li>
            </ul>
          </div>
          <div class="col-12 mt-5">
            <img src="assets/img/talk.png" class="img-fluid" />
            <h1>UMYO Tok</h1>
            <p>
              Similar to Tik Tok, UMYO Tok is a popular social media platform
              known for short-form videos. It allows users to create, share, and
              discover engaging video content that is often set to music. UMYO
              Tok videos typically range from a few seconds to one minute in
              length and cover a wide variety of topics, including music,
              comedy, dance, challenges, education, and more. The platform has
              gained immense popularity for its user-friendly interface and the
              ability for creators to showcase their creativity in a fun and
              interactive way, making it a hub for viral trends and creative
              expression.
            </p>
          </div>
          <div class="col-12 mt-5">
            <img src="assets/img/game.png" class="img-fluid" />
            <h1>UMYO Games</h1>
            <p>
              UMYO games are video games designed to be played on smartphones
              and tablets. These games are typically downloaded from app stores
              and are designed to be easily accessible and playable on mobile
              devices. They cover a wide range of genres, including puzzle,
              action, strategy, role-playing, and more. UMYO games are known for
              their portability and ease of use, making them a popular form of
              entertainment and a significant segment of the global gaming
              industry. Players can enjoy these games on the go, during short
              breaks, or in more extended gaming sessions, depending on the
              game's complexity and their preferences.
            </p>
          </div>
          <div class="col-12 mt-5">
            <img src="assets/img/mail.png" class="img-fluid" />
            <h1>UMYO Mail</h1>
            <p>Looking for privacy emails?</p>
            <p>
              Create virtual email IDs, with inbox, compose, send items, and
              drafts within our web panel.
            </p>
            <p>What is an UMYO Emailr?</p>
            <p>
              Similar to Yahoo, UMYO Mail is an email server computing system or
              software application responsible for sending, receiving, and
              storing email messages. It uses standard email protocols like SMTP
              (Simple Mail Transfer Protocol) for sending emails and IMAP
              (Internet Message Access Protocol) or POP3 (Post Office Protocol)
              for receiving and storing emails. Email servers play a crucial
              role in managing electronic communication by routing messages
              between users and ensuring their delivery.
            </p>
          </div>
          <div class="col-12 mt-5">
            <img
              src="assets/img/book.png"
              class="img-fluid"
              style="width: 4rem; height: 4rem"
            />
            <h1>Virtual Coupon Booklet</h1>
            <p>
              UMYO Virtual Coupon booklet links to other pages, videos, images,
              online contests, websites, social media and more.First ever Kiosk
              Touch screen business kiosk that can be shared by thousands to
              thousands within minutes.
            </p>
          </div>
          <div class="col-12 mt-5">
            <img
              src="assets/img/ufan.png"
              class="img-fluid"
              style="width: 4rem; height: 4rem"
            />
            <h1>UMYO Fans:</h1>
            <p>
              Empower content creators to monetize their work by receiving
              subscriptions from loyal followers and enthusiasts across various
              creative fields.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
  <footer class="footer">
    <div class="container-fluid">
      <p>Copyright 2023.All Right Reserved.</p>
    </div>
  </footer>
</div>
