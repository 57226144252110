<div *ngIf="isLoading" class="outer-spinner">
  <div class="loading-spinner"></div>
</div>

<div class="outside">
  <div class="table-responsive table-container table-sm mt-4">
    <table class="table">
      <thead>
        <tr>
          <th class="col-1">Date</th>
          <th class="col-1">Email</th>
          <th class="col-2">Total Package Amount</th>
          <th class="col-1 col-md-1">Bonus Amount</th>
          <th class="col-1">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <!-- <td>{{ user.email }}</td>
            <td>{{ user.packagePrice }}</td>
            <td>{{ user.stripe_subscription_id }}</td>
            <td>{{ user.balance_transaction_type }}</td> -->

          <!-- <td>
            <div>
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: 20px;
                "
              >
                <a>
                  <button class="btn btn-sm btn-primary">Edit</button>
                </a>
              </div>
            </div>
          </td> -->
        </tr>
      </tbody>
    </table>
  </div>
</div>
